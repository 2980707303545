export const formatStatus = (status) => {
    if (status === '0') {
        return "未向量化"
    } else if (status === '1') {
        return '向量化中'
    } else if (status === '2') {
        return '向量化完成'
    } else if (status === '3') {
        return '向量化失败'
    } else {
        return '未知状态'
    }
}
